<template>
  <v-col class="menuprodcard" cols="6" md="6">
    <v-card @click="showDialog()" class="text-left overflow-hidden prodcard">
      <v-img
        src="@/assets/images/smicon.svg"
        class="position-absolute prodcaticons"
        v-if="product.is_featured > 0"
      ></v-img>
      <v-img class="prodcardimg" :src="getProductPhoto()">
        <v-overlay absolute v-if="product.disabled === '1'">
          <v-chip small color="error" class="text-caption font-weight-6 sold-out">Sold Out</v-chip>
        </v-overlay>
      </v-img>
      <v-card-title class="subtitle-2 break-word p-2"
        ><b>
          {{ product.item_name | capitalize }}
        </b></v-card-title
      >
      <v-card-actions class="p-2 pt-0">
        <div
          class="
            subtitle-2
            d-flex
            align-items-center
            justify-content-center
            text-capitalize
            font-weight-600
          "
          style="letter-spacing: normal; height: 36px"
        >
          <div class="d-flex flex-column">
            <div
              class="text--disabled caption font-weight-600"
              v-if="product.discount"
            >
              <strike>{{ $n(getLowestPrice()[1], 'currency', 'en-MY') }}</strike>
            </div>

            <div>
              {{ $n((getLowestPrice()[1] - product.discount), 'currency', 'en-MY') }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>

        <v-btn
          class="buttonText--text"
          color="buttonBackground"
          min-width="unset"
          width="30px"
          small
          v-if="product.not_available == '1'"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <OrderModal
      :product="product"
      :opendialog="opendialog"
      v-on:modal-closed="handleModalClosed($event)"
      v-if="opendialog"
    >
    </OrderModal>
  </v-col>
</template>

<script>
import OrderModal from '../Menu/OrderModal';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: ['product'],
  data() {
    return {
      opendialog: false,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
    };
  },
  components: {
    OrderModal,
  },
  computed: {
    ...mapGetters('product', {
      sizes: 'getProductSizes',
    }),
  },
  methods: {
    ...mapMutations('cart', [
      'SET_ITEM_DETAILS',
      'SET_ITEM_SIZE',
      'SET_ITEM_QUANTITY',
      'SET_ITEM_PRICE',
      'SET_ITEM_DISCOUNT',
    ]),
    handleModalClosed() {
      this.opendialog = false;
      setTimeout(() => window.isDialogVisible = false, 100);
    },
    getProductPhoto() {
      if (!this.product.photo_s3 && !this.product.photo) {
        return require('@/assets/images/food-item-default.jpg');
      }

      return this.product.photo_s3 ? (`${this.cdnUrl}/${this.product.photo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.product.photo}`);
    },
    getLowestPrice() {
      return Object.entries(this.product.skus).sort(
        ([, v1], [, v2]) => v1 - v2
      )[0];
    },
    prettyPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    showDialog() {
      this.$gtag.event('view_item', {
        'event_category': 'navigation',
        'event_label': 'View Product (Menu)',
        'items': [{
          id: this.product.item_id,
          name: this.product.item_name,
          price: this.product.skus,
        }],
      });

      this.opendialog = true;
      window.isDialogVisible = true;

      this.SET_ITEM_DETAILS({
        id: this.product.item_id,
        name: this.product.item_name,
        category: this.product.category,
        nonTaxable: this.product.non_taxable,
      });
      this.SET_ITEM_SIZE({
        id: this.getLowestPrice()[0],
        name: this.sizes[this.getLowestPrice()[0]].size_name,
      });
      this.SET_ITEM_QUANTITY(1);
      this.SET_ITEM_PRICE(this.getLowestPrice()[1]);
      if (this.product.discount) {
        this.SET_ITEM_DISCOUNT(this.product.discount);
      }
    },
  },
};
</script>

<style>
.prodpricebtn:hover:before {
  background-color: #ffffff !important;
  opacity: 0 !important;
}
.v-card__title.subtitle-2 {
  min-height: 60px;
}
.menuprodcard .prodcard .v-btn__content {
  justify-content: flex-start;
  text-align: left;
}
.menuprodcard .prodcard {
  height: 100%;
}
.menuprodcard .prodcard .prodcardimg {
  width: 45vw;
  height: 40vw;
  object-fit: cover;
}
.prodcarddesc {
  font-size: 12px;
}
@media screen and (max-width: 375px) {
  .v-application .subtitle-2 {
    font-size: 0.75rem !important;
  }

  .menuprodcard .v-btn:not(.v-btn--round).v-size--small {
    height: 18px !important;
    width: 25px !important;
  }
  .menuprodcard .v-btn:not(.v-btn--round).v-size--small i {
    font-size: 16px;
  }
}

@media screen and (max-width: 360px) {
  .v-application .subtitle-2 {
    font-size: 0.75rem !important;
  }

  .menuprodcard .v-btn__content {
    font-size: 11px !important;
  }
}

@media screen and (min-width: 1024px) {
  .v-application .subtitle-2 {
    font-size: 0.8rem !important;
  }

  .menuprodcard .prodcard .prodcardimg {
    width: 180px;
    height: 160px;
  }
}
</style>

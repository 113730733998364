<template>
  <v-row v-if="!list.length" class="no-data justify-center"
    ><h6>No Data Found</h6></v-row
  >

  <v-row v-else class="restmenumainwrap pb-5 mb-5">
    <ProductCards
      v-for="product in list"
      :key="product.item_id"
      :product="product"
    ></ProductCards>
  </v-row>
</template>

<script>
import ProductCards from '@/components/Menu/ProductCards';
export default {
  name: 'GridView',
  components: {
    ProductCards,
  },
  props: ['list'],
};
</script>

<style scoped>
@media screen and (max-width: 414px) {
  .restmenumainwrap {
    /* margin-bottom: 60px !important; */
  }
}

.no-data {
  /* height: 45vh !important; */
}
</style>

<template>
  <div>
    <v-alert
      v-if="!canCheckout && !this.closed"
      class="alert text-caption p-1"
      type="info"
      transition="slide-y-reverse-transition"
      dense
      >{{ checkoutInfo() }}</v-alert
    >
    <v-bottom-navigation fixed :background-color="canCheckout ? 'toolbar' : 'disabled'" class="text-center">
      <v-container class="py-1">
        <v-row @click="routeToProceed()" v-if="!this.closed" align="center">
          <v-col cols="4" class="toolbarText--text">
            <div
              @click="sheet = true"
              style="width: 24px; height: 24px; cursor: pointer"
              :class="`
                bg-white
                ${canCheckout ? 'toolbar--text' : 'disabled--text'}
                subtitle-2
                d-flex
                justify-content-center
                align-items-center
                rounded-circle
              `"
            >
              {{ quantity }}
            </div>
          </v-col>
          <v-col cols="4">
            <p class="subtitle-1 toolbarText--text font-weight-bold" style="cursor: pointer">
              Checkout
            </p>
          </v-col>
          <v-col cols="4">
            <p class="subtitle-1 toolbarText--text">{{ $n(cart.totals.subTotal, 'currency', 'en-MY') }}</p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p class="subtitle-1 toolbarText--text font-weight-bold" style="cursor: pointer">
              Temporarily Closed
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

export default {
  props: ['closed'],
  data: function () {
    return {
      sheet: false,
    };
  },
  computed: {
    ...mapState('cart', {
      cart: (state) => state.cart,
    }),
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      quantity: 'getQuantity',
    }),
    canCheckout() {
      let minOrder = 0;
      let maxOrder = 0;

      if (this.isDelivery) {
        minOrder = this.profile.delivery_minimum_order;
        maxOrder = this.profile.delivery_maximum_order;
      } else if (this.isPickup) {
        minOrder = this.profile.pickup_minimum_order;
        maxOrder = this.profile.pickup_maximum_order;
      }

      return this.cart.totals.subTotal >= minOrder && (maxOrder === 0 || this.cart.totals.subTotal <= maxOrder);
    },
  },
  methods: {
    routeToProceed() {
      if (this.canCheckout) {
        this.$router.push({ name: 'checkout' }).catch(() => {});
      }
    },
    checkoutInfo() {
      let minOrder = 0;
      let maxOrder = 0;

      if (this.isDelivery) {
        minOrder = this.profile.delivery_minimum_order;
        maxOrder = this.profile.delivery_maximum_order;
      } else if (this.isPickup) {
        minOrder = this.profile.pickup_minimum_order;
        maxOrder = this.profile.pickup_maximum_order;
      }

      if (minOrder > this.cart.totals.subTotal) {
        return `Add ${this.$n((minOrder - this.cart.totals.subTotal), 'currency', 'en-MY')} more to checkout`;
      } else if (maxOrder > 0 && this.cart.totals.subTotal > maxOrder) {
        return `Reduce ${this.$n((this.cart.totals.subTotal - maxOrder), 'currency', 'en-MY')} or more to checkout`;
      } else {
        return '';
      }
    },
  },
  mounted() {
    dayjs.extend(isBetween);
  },
};
</script>

<style scoped>
.alert {
  position: fixed;
  bottom: 40px;
  z-index: 5;
  width: 100%;
  text-align: center;
  border-radius: 16px 16px 0px 0px !important;
  opacity: 0.8;
}
@media screen and (max-width: 360px) {
  .v-application .subtitle-1 {
    font-size: 0.9rem !important;
  }
}
@media screen and (min-width: 1024px) {
  .alert {
    width: 414px;
  }
}
</style>

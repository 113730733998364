<template>
  <v-row v-if="!list.length" class="no-data justify-center"
    ><h6>No Data Found</h6></v-row
  >

  <v-list three-line class="p-0 m-0 mb-4" v-else>
    <ListProductCards
      v-for="product in list"
      :key="product.item_id"
      :product="product"
    ></ListProductCards>
  </v-list>
</template>

<script>
import ListProductCards from '@/components/Menu/ListProductCards';
export default {
  name: 'ListView',
  components: { ListProductCards },
  props: ['list'],
};
</script>

<style scoped>
.no-data {
  height: 200px !important;
}
</style>

<template>
  <v-list-item
    class="menuprodlist p-0 m-0"
    @click="showDialog()"
  >
    <v-img
      src="@/assets/images/smicon.svg"
      class="position-absolute prodcaticons"
      v-if="product.is_featured > 0"
    ></v-img>
    <v-list-item-content>
      <v-list-item-title class="text-body-2 break-word mt-1 mb-2">
        <b>{{ product.item_name | capitalize }}</b>
      </v-list-item-title>
      <v-list-item-subtitle class="prodlistdesc" v-html="product.item_description" v-if="product.item_description">
      </v-list-item-subtitle>
      <v-list-item-subtitle class="subtitle-2 d-flex flex-row align-items-end">
        <div class="d-flex flex-column mr-1">
          <div
            class="text--disabled caption font-weight-600"
            v-if="product.discount"
          >
            <strike>{{ $n(getLowestPrice()[1], 'currency', 'en-MY') }}</strike>
          </div>

          <div class="font-weight-6">
            {{ $n((getLowestPrice()[1] - product.discount), 'currency', 'en-MY') }}
          </div>
        </div>
        <v-btn
          class="buttonText--text"
          color="buttonBackground"
          min-width="unset"
          width="16px"
          small
          v-if="product.not_available == '1'"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar class="prodlistimg">
      <v-img :src="getProductPhoto()">
        <v-overlay absolute v-if="product.disabled === '1'">
          <v-chip small color="error" class="text-caption font-weight-6 sold-out px-2">Sold Out</v-chip>
        </v-overlay>
      </v-img>
    </v-list-item-avatar>
    <OrderModal
      :product="product"
      :opendialog="opendialog"
      v-on:modal-closed="handleModalClosed($event)"
      v-if="opendialog"
    >
    </OrderModal>
  </v-list-item>
</template>

<script>
import OrderModal from '../Menu/OrderModal';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: ['product'],
  data() {
    return {
      opendialog: false,
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
    };
  },
  components: {
    OrderModal,
  },
  computed: {
    ...mapGetters('product', {
      sizes: 'getProductSizes',
    }),
  },
  methods: {
    ...mapMutations('cart', [
      'SET_ITEM_DETAILS',
      'SET_ITEM_SIZE',
      'SET_ITEM_QUANTITY',
      'SET_ITEM_PRICE',
      'SET_ITEM_DISCOUNT',
    ]),
    handleModalClosed() {
      this.opendialog = false;
      setTimeout(() => window.isDialogVisible = false, 100);
    },
    getProductPhoto() {
      if (!this.product.photo_s3 && !this.product.photo) {
        return require('@/assets/images/food-item-default.jpg');
      }

      return this.product.photo_s3 ? (`${this.cdnUrl}/${this.product.photo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.product.photo}`);
    },
    getLowestPrice() {
      return Object.entries(this.product.skus).sort(
        ([, v1], [, v2]) => v1 - v2
      )[0];
    },
    prettyPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    showDialog() {
      this.$gtag.event('view_item', {
        'event_category': 'navigation',
        'event_label': 'View Product (Menu)',
        'items': [{
          id: this.product.item_id,
          name: this.product.item_name,
          price: this.product.skus,
        }],
      });

      this.opendialog = true;
      window.isDialogVisible = true;

      this.SET_ITEM_DETAILS({
        id: this.product.item_id,
        name: this.product.item_name,
        category: this.product.category,
        nonTaxable: this.product.non_taxable,
      });
      this.SET_ITEM_SIZE({
        id: this.getLowestPrice()[0],
        name: this.sizes[this.getLowestPrice()[0]].size_name,
      });
      this.SET_ITEM_QUANTITY(1);
      this.SET_ITEM_PRICE(this.getLowestPrice()[1]);
      if (this.product.discount) {
        this.SET_ITEM_DISCOUNT(this.product.discount);
      }
    },
  },
};
</script>

<style>
.menuprodlist {
  border-top: 0.35px solid rgba(0, 0, 0, 0.1);
  background: var(--v-background);
}
.menuprodlist:first-child {
  border-top: none;
}
.v-list--three-line .menuprodlist.v-list-item .v-list-item__content {
  align-self: flex-start !important;
}
.menuprodlist .prodlistimg {
  border-radius: 5px;
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}
.menuprodlist .prodlistdesc {
  font-size: 11px !important;
  margin-bottom: 8px;
  -webkit-line-clamp: 4 !important;
}
.menuprodlist .v-btn:not(.v-btn--round).v-size--small {
  margin-top: 2px;
  margin-left: 4px;
  height: 14px !important;
  width: 16px !important;
}
.menuprodlist .v-btn:not(.v-btn--round).v-size--small i {
  font-size: 14px;
}
</style>

<template>
  <v-bottom-sheet v-model="showService">
    <v-card min-height="30vh" max-height="60vh" class="overflow-y-auto p-0">
      <v-card-title class="text-button font-weight-6 justify-center">
        <v-icon color="primary" class="mr-2">mdi-food</v-icon>
        Services
      </v-card-title>
      <v-card height="8" color="listBackground"></v-card>
      <v-card
        flat
        color="listBackground"
        class="pb-2 px-0"
      >
        <v-list color="#ffffff">
          <v-list-item
            v-for="service in services"
            :key="service.value"
            @click="selectService(service.label, service.value)"
          >
						<v-list-item-avatar class="my-0">
              <v-icon color="primary">{{ serviceIcons[service.value] }}</v-icon>
						</v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-caption font-weight-6">{{ service.label }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="selected == service.value">
              <v-icon color="primary">mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ServiceBottomSheet',
  props: ['visible'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState('cart', {
      selected: (state) => state.service,
    }),
    ...mapState('merchant', {
      services: (state) => state.profile?.service.filter(s => s.value !== 'dinein'),
    }),
    ...mapGetters('cart', {
      serviceIcons: ["getServiceIcons"],
    }),
    showService: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-service', false);
        }
      },
    },
  },
  methods: {
    ...mapMutations('cart', ['SET_SERVICE']),
    selectService(label, value) {
      this.$gtag.event('select-service', {
        'event_category': 'navigation',
        'event_label': label,
        'event_value': value,
      });

      this.SET_SERVICE(value);
      window.location.reload();
    }
  },
};
</script>
<style scoped>
.v-list-item:not(:first-child) {
  border-top: 0.55px solid rgba(100, 100, 100, .1);
}
</style>